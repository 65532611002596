import bannerfw from '../assets/img/banner.jpeg';
import image1 from '../assets/img/FVCKINGFOOS-2.jpg';

function Home() {
    return (
        <>
        <div class="background-logo"></div>
    <div className={"banner"}>
        <span>THE</span>
        <span>F#CKING FOOS</span>
      </div>
      
      <div className={"content-container"}>
        <div className={"paragraph paragraph--content paragraph--medium"}>
        <p>Een avond met The Fucking Foo’s is hetzelfde als gelanceerd worden in een oude Sojoez-1 raket uit de jaren '60: Je weet dat het een ervaring is waar je jaren later nog over vertelt op de verjaardag van je kleinkinderen, maar tijdens de trip hou je je vast aan alles wat je dierbaar is terwijl het universum langs je oren suist.</p>
        <p>Alle hoogtepunten komen voorbij en je hebt nauwelijks tijd om adem te halen. En als je 's-avonds laat totaal uitgeput en voldaan in bed ligt mompel je in de verte de melodie van "Everlong"...</p> 
        </div>
      </div>

      <div className={'divider'}></div>

      <div className={"paragraph"}>
        <div className={"banner-img"}>
          <img src={bannerfw} alt="home banner"/>
        </div>
      </div>

      <div className={'divider'}></div>

      <div className={"paragraph paragraph--content paragraph--small"}>
        <h2 id="tour">TOUR</h2>
        <div className='tour-list'>
          <h3>2024</h3>
          <a href={'https://poppodiumboerderij.nl/programma/fucking-foos/'} target='_blank' rel="noreferrer" className='tour-row past'>
            <div className="tour-date">28 Maart</div>
            <div className='tour-name'>Poppodium Boerderij<br /><i>Zoetermeer</i></div>
          </a>
          <div className='tour-row past'>
            <div className="tour-date">05 Januari</div>
            <div className='tour-name'>De Paap<br /><i>Den Haag</i></div>
          </div>
          <h3>2023</h3>
          
          <div className='tour-row past'>
            <div className="tour-date">07 Juli</div>
            <div className='tour-name'>Popradar, Den Haag</div>
          </div>
          
        </div>
      </div>

      <div className={'divider'}></div>

      <div className={"paragraph paragraph--content paragraph--small"}>
        <h2 id="media">MEDIA AND SH*T</h2>
        <img src={image1} alt="promo 1" />

        <iframe className={"video-iframe"} src="https://www.youtube.com/embed/nb0nYLB1m30?si=MpJGNTn2XAIdsEft" title="F#CKING FOOS LIVE TEASE" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
      </div>

      <div className={'divider'}></div>

      <div className={"paragraph paragraph--content paragraph--small"}>
        <h2 id="media">SOCIALS</h2>
        <a href="https://www.facebook.com/fvckingfoos" target="_blank" rel="noreferrer">FACEBOOK</a><br />
        <a href="https://www.instagram.com/fvckingfoos/" target="_blank" rel="noreferrer">INSTAGRAM</a><br />
        <a href="https://www.youtube.com/@fvckingfoos" target="_blank" rel="noreferrer">YOUTUBE</a>
      </div>
      </>
);
}

export default Home;
